import { Grid, Paper } from '@mui/material';

import { ContentAreaBodyProps } from './ContentAreaBody.types';

export const ContentAreaBody: React.FC<ContentAreaBodyProps> = (
    props: Readonly<ContentAreaBodyProps>,
) => {
    const { content, withPaper, className } = props;

    const rootProps: any = {};
    if (className) {
        rootProps.className = className;
    }

    if (withPaper) {
        return (
            <Paper
                {...rootProps}
                style={{ borderRadius: '0px' }}
                automation-id="content-area-body-paper-element"
            >
                {content}
            </Paper>
        );
    }
    return (
        <Grid {...rootProps} automation-id="content-area-body-grid-element">
            {content}
        </Grid>
    );
};
